import * as React from 'react';
import ThemeSlider from '../components/ThemeSlider';
import { Outlet, Link, useNavigate } from "react-router-dom";
import Constants from '../../utilities/Constants';

const Home = () => {  
    const [loading, setLoading] = React.useState(true);

    return (
        <>
            <ThemeSlider />
            <section>
                <div className='mx-auto hanging-msg' >
                    <span className='text-brand-primary'> ARISE, AWAKE & </span>
                    <span className='text-brand-dark'> STOP NOT TILL </span>
                    <span className='text-brand-secondary'> THE GOAL IS REACHED !! </span>
                </div>
            </section>

            <section>
                <h1 className='text-center mt-5'>
                    {Constants.pages.about.title}
                </h1>
                <p className='w-50 text-center mx-auto'>
                Shiwajirao Vishnu Nalawade was born on 1 June 1954 to Vishnu Genuji Nalawade and Vithabai Vishnu Nalawade. His father's name is Shri. Vishnu Genuji Nalawade, who was an employee of Firestone Rubber Company in Mumbai. His mother Smt.Vithabai, on the other hand, looked after the family farm at Dholwad, Taluka, Junnar District, Pune in Maharashtra State, India.
                </p>
                <div className='text-center mb-3'>
                    <Link type="button" class="btn bg-brand mx-auto rounded-pill" to={Constants.pages.about.link}>Read More <i class="fas fa-arrow-right"></i></Link>
                </div>
                
            </section>

            <section className='odd'>
                <div className='container p-5'>

                    <h2 className='text-center m-5'>
                        Achievements
                    </h2>

                    <div className="row">
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Special title treatment</h5>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className=''>
                <div className='container p-5'>

                    <h2 className='text-center m-5'>
                        Events
                    </h2>

                    <div className="row">
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Special title treatment</h5>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='odd'>
                <div className='container p-5'>

                    <h2 className='text-center m-5'>
                        Work
                    </h2>

                    <div className="row">
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Special title treatment</h5>
                                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Special title treatment</h5>
                                <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
            
    )
}

export default Home;
