import Nav from 'react-bootstrap/Nav';
import Constants from '../../utilities/Constants';
import { Link } from 'react-router-dom';

function ThemeFooter() {

  const d = new Date();
  let year = d.getFullYear();
  
  return (
    <footer style={{}}>
      <section className="bg-brand p-3">
        <div className="row container-fluid">
          <div className="col-sm-4">
              <h4>Links</h4>
              <Nav className="flex-column nav-brand">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  {Constants.externalLinks.map(
                    e => 
                    <li class="nav-item">
                      <a class="nav-link text-white" aria-current="page" href={e.link} target="_blank">
                        <i className={e.icon}></i>{' '}
                        {e.text}
                      </a>
                    </li> 
                  )}
                </ul>
              </Nav>
          </div>
          <div className="col-sm-4">
              <h4>Quick links</h4>
              <Nav className="flex-column nav-brand">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  {Constants.menu.map(
                    e => 
                    e.type == 'external' ?
                    <li class="nav-item">
                      <a class="nav-link text-white" aria-current="page" href={e.link} target="_blank">
                        <i className={e.icon}></i>{' '}
                        {e.text}
                      </a>
                    </li> :
                    <li class="nav-item">
                      <Link class="nav-link text-white" aria-current="page" to={e.link} >
                        <i className={e.icon}></i>{' '}
                        {e.text}
                      </Link>
                    </li>
                  )}
                </ul>
              </Nav>
          </div>

          <div className="col-sm-4">
            <h4>Socials</h4>
            <Nav className="flex-column nav-brand">
              <ul class="navbar-nav me-auto  mb-2 mb-lg-0">
                  {Constants.social.map(
                    e => 
                    <li class="nav-item">
                      <a class="nav-link text-brand" aria-current="page" href={e.link} target="_blank">
                        <i className={`${e.icon}`}></i>{' '}
                        {e.text}
                      </a>
                    </li> 
                  )}
                </ul>
              </Nav>
          </div>
        </div>
      </section>
      <section className="bg-brand-primary p-3">
        <div className="row container-fluid">
          <div className="col-sm-4">
            <h4>Address</h4>
            <Nav className="flex-column nav-brand">
              <ul class="navbar-nav me-auto  mb-2 mb-lg-0">
                  {Constants.addresses.map(
                    e => 
                    <li class="nav-item">
                      <a href="#">
                        <i className={`${e.icon}`}></i>{' '}
                        {e.text}
                      </a>
                    </li> 
                  )}
                </ul>
              </Nav>
          </div>
          <div className="col-sm-4">
              <h4>Contacts</h4>
              <Nav className="flex-column nav-brand">
              <ul class="navbar-nav me-auto  mb-2 mb-lg-0">
                  {Constants.contacts.map(
                    e => 
                    <li class="nav-item">
                      <a class="nav-link" aria-current="page" href={`tel:${e.number}`} >
                        <i className={`${e.icon}`}></i>{' '}
                        {e.number}
                      </a>
                    </li> 
                  )}
                </ul>
              </Nav>
          </div>
          <div className="col-sm-4">
            <h4>Email</h4>
            <Nav className="flex-column nav-brand">
              <ul class="navbar-nav me-auto  mb-2 mb-lg-0">
                  {Constants.emails.map(
                    e => 
                    <li class="nav-item">
                      <a class="nav-link" aria-current="page" href={`mailto:${e.email}`} >
                        <i className={`${e.icon}`}></i>{' '}
                        {e.email}
                      </a>
                    </li> 
                  )}
                </ul>
              </Nav>
          </div>
        </div>
      </section>
      <section className="odd p-3 text-brand-primary">
        <div className="row container-fluid">
          <div className="col-sm-6 text-center text-sm-start">
            Powered by <a href="http://i-vote.in" target="_blank" rel="noreferrer">I-Vote</a>
          </div>
          <div className="col-sm-6 text-center text-sm-end ">
            Copyright {year} <a href='http://www.omconsultancy.info/' target='_blank'>OM Consultancy services</a>, All Rights Reserved.
          </div>
        </div>
      </section>
    </footer>
  );
}

export default ThemeFooter;