
import Constants from "../../utilities/Constants";

function ThemeInfoStrip() {
  return (
    <div className=" bg-brand navbar navbar-expand-lg navbar-light p-0 text-white d-none d-xxl-flex"  >
                <div class="container-fluid">
                  <div class="collapse navbar-collapse" id="navbarText">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    {Constants.externalLinks.map(
                      e => <li class="nav-item">
                      <a class="nav-link text-white" aria-current="page" href={e.link} target="_blank">
                        {e.text}
                      </a>
                    </li>
                    )}
                    </ul>
                    
                  </div>
                </div>
              </div>
  );
}

export default ThemeInfoStrip;